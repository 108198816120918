import { takeEvery } from '@redux-saga/core/effects';
import {
    UpdateActionType,
    createUpdateAction,
} from '@core/store/actions';
import { createType } from './core';
import { ISagaHandler } from './saga';
import { UnexpectedSagaError } from './utils';

type TSagaFn<TPayload> = ((value: Partial<TPayload>) => any) | (() => any);

/**
 * Implements a simple callback to handle any logic on foreground and independent from UI components.
 *
 * @typeParam TPayload - тип параметра, передаваемый в параметрах функции
 *
 * @param prefix - Префик duck модуля
 * @param actionName - Имя хендлера
 * @param saga - saga функция, принимающая переданный параметр
 * @param opts
 * @returns
 *
 * @category Duck Handlers
 *
 * @see {@link ISagaHandler}
 * @see {@link useActions}
 *
 * @example
 * // duck.ts
 * type OpenExchangeModalHandlerPayload = {
 *      loanId: number;
 *      mode: 'sell'|'buy';
 *      price?: number;
 *      count?: number;
 * };
 *
 * export const exchangeOpenModalHandler = simpleSagaHandlerV2<OpenExchangeModalHandlerPayload>(
 *      PREFIX, 'open_modal',
 *      function* ({ loanId, mode, price, count }) {
 *          if (mode === 'sell') {
 *              const exchange = yield fetchAndSelectFromCollection(exchangeOverviewHandler, loanId);
 *              price = price ?? exchange?.recommended_price ?? 1.0;
 *              count = count ?? exchange?.personal?.count ?? 1;
 *          } else {
 *              price = price ?? 1.0;
 *              count = count ?? 1;
 *          }
 *
 *          yield put(exchangeModalStateHandler.update({
 *              loanId,
 *              price,
 *              count
 *          }));
 *
 *          yield put(exchangeModalHandler.open());
 *      }
 * );
 *
 * // ExchangeButton.tsx
 * const openExchangeModal = useActions(exchangeOpenModalHandler.action);
 * const didSellButtonClicked = useCallback(() => {
 *      openExchangeModal({
 *          loanId,
 *      });
 * }, [ loanId, openExchangeModal ]);
 */
export function simpleSagaHandlerV2<TPayload = unknown>(prefix: string, actionName: string, saga: TSagaFn<TPayload>, opts: any = {}): ISagaHandler<TPayload> {
    const actionKey = opts.key || 'value';

    const ACTION = createType(prefix, actionName);
    const action = createUpdateAction<TPayload>(ACTION, actionKey);

    function* handleActionEvent(event: UpdateActionType<TPayload>) {
        try {
            yield* saga(event.value);
        } catch (cause) {
            const error = new UnexpectedSagaError(
                cause.message,
                cause,
                actionName,
                prefix
            );

            console.error(error);
        }
    }

    const effects = [
        takeEvery(ACTION, handleActionEvent as any),
    ];

    return {
        ACTION,
        action,
        effects,
        // Сохраняем ссылку на сагу для дальнейшего использования в тестировании
        __saga: saga,
    };
}
